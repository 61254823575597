import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FaChevronDown } from 'react-icons/fa';
import * as Yup from 'yup';
import Breadcrumb from 'components/commercetools-ui/account/breadcrumb';
import { stateOptions } from 'helpers/constants/stateList';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useCart } from 'frontastic';
import { submitSweepstake, validateSweepstake } from 'frontastic/actions/account';
import MaxLimit from '../Modals/maxLimitModal';

const Sweepstake = ({
  sweepImageUrl,
  sweepsId,
  startDate,
  endDate,
  sweepExpiryMsg,
  title,
  subTitle,
  rulesLinkText,
  pdfLink,
  regInfoText1,
  regInfoText2,
}) => {
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { studioConfig } = useCart();
  const sweepstakesRegionsJson = studioConfig?.sweepstakesRegionsJson;
  const [nearestExchanges, setNearestExchanges] = useState([]);
  const { loggedIn } = useAccount();
  const [showSweepForm, setShowSweepForm] = useState(false);
  const [validateSweepError, setValidateSweepError] = useState('');
  const [sweepFormError, setSweepFormError] = useState('');
  const [submitSuccessMsg, setSubmitSuccessMsg] = useState('');
  const [maxLimitModal, setMaxLimitModal] = useState(false);
  const [isSweepExpired, setIsSweepExpired] = useState(false);
  const [currentpagePathname, setCurrentpagePathname] = useState('');

  const sweepstakeValidation = async () => {
    const res = await validateSweepstake(sweepsId);
    const validateResponse = res as any;
    if (
      ('errorCode' in validateResponse && validateResponse?.errorCode === 500) ||
      !validateResponse?.info ||
      validateResponse?.info === 'NotCustomer'
    ) {
      setShowSweepForm(false);
      if (loggedIn) {
        setValidateSweepError(validateResponse?.body ? validateResponse?.body : 'SweepStake Validation Failed !');
      } else {
        setValidateSweepError(validateResponse?.body + ' or Sign In if logged out');
      }
    } else {
      setValidateSweepError('');
      setShowSweepForm(true);
    }
  };

  useEffect(() => {
    sweepstakeValidation();
    evaluateSweepstakeExpiration();
    setCurrentpagePathname(window.location.pathname);
  }, []);

  useEffect(() => {
    if (sweepstakesRegionsJson) {
      try {
        setNearestExchanges(Object.entries(JSON.parse(sweepstakesRegionsJson)));
      } catch (error) {
        console.error('Error Parsing sweepstakesRegionsJson:', error);
      }
    }
  }, [sweepstakesRegionsJson]);

  const handlePostalCodeInput = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    const formattedValue =
      sanitizedValue.slice(0, 5) + (sanitizedValue.length > 5 ? '-' : '') + sanitizedValue.slice(5, 10);
    const truncatedValue = formattedValue.slice(0, 10);
    e.target.value = truncatedValue;
  };

  const sweepstakeschema = Yup.object().shape({
    firstName: Yup.string()
      .required(formatCommonMessage({ id: 'fNameRequired', defaultMessage: 'First name is required.' }))
      .matches(
        /^[A-Za-z]+(?:\s(?:[A-Za-z ]+)?)?$/,
        formatCommonMessage({ id: 'validFirstName', defaultMessage: 'Enter a valid first name.' }),
      ),
    middleName: Yup.string(),
    lastName: Yup.string()
      .required(formatCommonMessage({ id: 'lNameRequired', defaultMessage: 'Last name is required.' }))
      .matches(
        /^[A-Za-z]+(?:\s(?:[A-Za-z ]+)?)?$/,
        formatCommonMessage({ id: 'validLastName', defaultMessage: 'Enter a valid last name.' }),
      ),
    streetName: Yup.string()
      .required(formatCommonMessage({ id: 'street1Required', defaultMessage: 'street 1 is required.' }))
      .matches(
        /^[A-Za-z0-9\s\-]+$/,
        formatCommonMessage({ id: 'validStreetName', defaultMessage: 'Enter a valid street name.' }),
      ),
    streetName2: Yup.string(),
    city: Yup.string()
      .required(formatCommonMessage({ id: 'cityRequired', defaultMessage: 'City is required.' }))
      .matches(/^[A-Za-z\s]+$/, formatCommonMessage({ id: 'validCity', defaultMessage: 'Enter a valid city name.' })),
    state: Yup.string()
      .required(formatCommonMessage({ id: 'stateRequired', defaultMessage: 'Please select state.' }))
      .matches(/^[A-Za-z\s]+$/, formatCommonMessage({ id: 'validState', defaultMessage: 'Enter a valid state name.' })),
    zipCode: Yup.string()
      .required(formatCommonMessage({ id: 'zipRequired', defaultMessage: 'Zip code is required.' }))
      .test(
        'testOnlyNum',
        formatCommonMessage({ id: 'enterValidZip', defaultMessage: 'Please enter a valid zip code' }),
        (val) => {
          val = val ? val : '';
          const regExp = new RegExp('^[\\d-]+$');
          return regExp.test(val);
        },
      )
      .max(10, formatCommonMessage({ id: 'zipShouldBe10', defaultMessage: 'Zip code should be 5-10 digits.' }))
      .min(5, formatCommonMessage({ id: 'zipShouldBe5', defaultMessage: 'Zip code should be 5 digits.' })),
    phone: Yup.string()
      .required(formatCommonMessage({ id: 'phoneRequired', defaultMessage: 'Phone Number is required.' }))
      .matches(
        /^(?:\+?\d{10,14}|\d{10,15})$/,
        formatCommonMessage({ id: 'enterValidPhone', defaultMessage: 'Please enter a valid Phone Number.' }),
      )
      .min(10, formatCommonMessage({ id: 'phoneShouldBe', defaultMessage: 'Phone Number should be 10 to 15 digits.' }))
      .max(15, formatCommonMessage({ id: 'phoneShouldBe', defaultMessage: 'Phone Number should be 10 to 15 digits.' })),
    email: Yup.string()
      .required(formatCommonMessage({ id: 'emailRequired', defaultMessage: 'Email is required.' }))
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        formatCommonMessage({ id: 'validEmail', defaultMessage: 'Enter a valid Email Address.' }),
      ),
    nearestExchange: Yup.string().required(formatCommonMessage({ id: 'storeRequired', defaultMessage: 'Required' })),
  });

  const sweepstakeForm: any = {
    resolver: yupResolver(sweepstakeschema),
    mode: 'onBlur',
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm(sweepstakeForm);

  const sweepstakeFormHandler = async (values: any) => {
    const payload = {
      sweepstakesForm: {
        email: values.email,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        streetName1: values.streetName,
        streetName2: values.streetName2,
        postalCode: values.zipCode,
        phone: values.phone,
        city: values.city,
        state: values.state,
        regionExchange: values.nearestExchange,
      },
      sweepId: sweepsId,
    };
    const res = await submitSweepstake(payload);
    const formResponse = res as any;
    if ('errorCode' in formResponse && formResponse?.errorCode === 500) {
      setSweepFormError(formResponse?.body ? formResponse?.body : 'Sweepstake Form Submission Failed !');
    } else {
      setSubmitSuccessMsg('Sweepstake Form submitted Successfully !');
    }
    setMaxLimitModal(true);
  };
  useEffect(() => {
    if (validateSweepError) {
      setValidateSweepError('');
      sweepstakeValidation();
    }
  }, [loggedIn]);

  const formatingDate = (givenDate) => {
    const originalDate = new Date(givenDate);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
    const day = String(originalDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const evaluateSweepstakeExpiration = () => {
    const today = new Date();
    const formattedStartDate = formatingDate(startDate);
    const formattedEndDate = formatingDate(endDate);
    const sweepStartDate = new Date(formattedStartDate);
    const sweepEndDate = new Date(formattedEndDate);

    if (today >= sweepStartDate && today <= sweepEndDate) {
      setIsSweepExpired(false);
    } else {
      setIsSweepExpired(true);
    }
  };
  const refreshPage = (event) => {
    event.preventDefault();
    window.location.reload();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <>
      <Breadcrumb pageName="sweepStake" pageLink={{ name: 'Win-Reign', href: currentpagePathname }} />
      <div className="mt-3">
        <p className="text-[35.7px] text-[#333333]">{title}</p>

        <img src={sweepImageUrl} alt={subTitle}></img>
        <div className="mt-3 pb-[15px] text-[18px] font-bold text-[#2A348B]">
          <h2>{subTitle}</h2>
        </div>
        <p className="text-[14px] text-[#043C63]">
          <a href={pdfLink} target="_blank" rel="noreferrer">
            {rulesLinkText}
          </a>
        </p>
        {validateSweepError && (
          <div className=" my-5 flex  justify-center p-2 text-center text-[17px] font-bold text-red-900">
            <p className=" w-2/3 rounded-sm border border-red-300 bg-red-100 p-4">{validateSweepError}</p>
          </div>
        )}
        {isSweepExpired && loggedIn && (
          <div className=" my-5 flex  justify-center p-2 text-center text-[17px] font-bold text-red-900">
            <p className=" w-2/3 rounded-sm border border-red-300 bg-red-100 p-4">{sweepExpiryMsg}</p>
          </div>
        )}
      </div>

      {loggedIn && showSweepForm && !isSweepExpired && (
        <section>
          <p className="mt-2">{regInfoText1}</p>
          <strong className="mb-4 text-[#333333]">{regInfoText2}</strong>
          <form onSubmit={handleSubmit(sweepstakeFormHandler)}>
            <section className="flex w-1/2 flex-col gap-1 text-[16px]">
              <section className="my-2 flex flex-col ">
                <div>
                  <label htmlFor="firstName">First Name</label>
                  <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">*</span>
                </div>
                <input
                  type="text"
                  id="firstName"
                  maxLength={20}
                  {...register('firstName')}
                  className={`block w-full rounded py-[6px] px-[10px] ${
                    errors?.firstName?.message ? 'border-red-500' : ''
                  }`}
                />
                {errors?.firstName?.message && (
                  <span className="inline-flex text-sm text-red-500" role="alert">
                    {errors?.firstName?.message}
                  </span>
                )}
              </section>
              <section className="my-2 flex flex-col ">
                <label htmlFor="middleName">Middle Init</label>
                <input
                  type="text"
                  id="middleName"
                  maxLength={10}
                  {...register('middleName')}
                  className="block w-full rounded py-[6px] px-[10px]"
                />
              </section>
              <section className="my-2 flex flex-col ">
                <div>
                  <label htmlFor="lastName">Last Name</label>
                  <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">*</span>
                </div>
                <input
                  type="text"
                  id="lastName"
                  maxLength={20}
                  {...register('lastName')}
                  className={`block w-full rounded py-[6px] px-[10px] ${
                    errors?.lastName?.message ? 'border-red-500' : ''
                  }`}
                />
                {errors?.lastName?.message && (
                  <span className="inline-flex text-sm text-red-500" role="alert">
                    {errors?.lastName?.message}
                  </span>
                )}
              </section>
              <section className="my-2 flex flex-col ">
                <div>
                  <label htmlFor="street1">Street 1</label>
                  <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">*</span>
                </div>
                <input
                  type="text"
                  id="street1"
                  {...register('streetName')}
                  className={`block w-full rounded py-[6px] px-[10px] ${
                    errors?.streetName?.message ? 'border-red-500' : ''
                  }`}
                />
                {errors?.streetName?.message && (
                  <span className="inline-flex text-sm text-red-500" role="alert">
                    {errors?.streetName?.message}
                  </span>
                )}
              </section>
              <section className="my-2 flex flex-col ">
                <label htmlFor="street2">Street 2</label>
                <input
                  type="text"
                  id="street2"
                  {...register('streetName2')}
                  className="block w-full rounded py-[6px] px-[10px]"
                />
              </section>
              <section className="my-2 flex flex-col ">
                <div>
                  <label htmlFor="city">City</label>
                  <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">*</span>
                </div>
                <input
                  type="text"
                  id="city"
                  {...register('city')}
                  className={`block w-full rounded py-[6px] px-[10px] ${errors?.city?.message ? 'border-red-500' : ''}`}
                />
                {errors?.city?.message && (
                  <span className="inline-flex text-sm text-red-500" role="alert">
                    {errors?.city?.message}
                  </span>
                )}
              </section>
              <section className="my-2 ">
                <div>
                  <label htmlFor="state">State</label>
                  <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">*</span>
                </div>
                <div className="relative">
                  <select
                    id="state"
                    {...register('state')}
                    className={`block w-full rounded py-[6px] px-[10px] ${
                      errors?.state?.message ? 'border-red-500' : ''
                    }`}
                  >
                    <option value=""></option>
                    {stateOptions.map((state) => (
                      <option key={state.data} value={state.data}>
                        {state.display}
                      </option>
                    ))}
                  </select>
                  <FaChevronDown
                    aria-hidden="true"
                    className="pointer-events-none absolute top-1/2 right-3 -translate-y-1/2 text-xs text-black-500"
                  />
                </div>
                {errors?.state?.message && (
                  <span className="inline-flex text-sm text-red-500" role="alert">
                    {errors?.state?.message}
                  </span>
                )}
              </section>
              <section className="my-2 flex flex-col ">
                <div>
                  <label htmlFor="zipcode">Zip</label>
                  <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">*</span>
                </div>
                <input
                  type="text"
                  id="zipcode"
                  onInput={handlePostalCodeInput}
                  maxLength={10}
                  {...register('zipCode')}
                  className={`block w-full rounded py-[6px] px-[10px] ${
                    errors?.zipCode?.message ? 'border-red-500' : ''
                  }`}
                />
                {errors?.zipCode?.message && (
                  <span className="inline-flex text-sm text-red-500" role="alert">
                    {errors?.zipCode?.message}
                  </span>
                )}
              </section>
              <section className="my-2 flex flex-col ">
                <div>
                  <label htmlFor="phoneNo">Phone</label>
                  <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">*</span>
                  <span className="ml-2 text-xs font-normal italic">{`(no dashes) `}</span>
                </div>
                <input
                  id="phoneNo"
                  type="text"
                  maxLength={15}
                  {...register('phone')}
                  className={`block w-full rounded py-[6px] px-[10px] ${
                    errors?.phone?.message ? 'border-red-500' : ''
                  }`}
                />
                {errors?.phone?.message && (
                  <span className="inline-flex text-sm text-red-500" role="alert">
                    {errors?.phone?.message}
                  </span>
                )}
              </section>
              <section className="my-2 flex flex-col ">
                <div>
                  <label htmlFor="mailId">Email</label>
                  <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">*</span>
                </div>
                <input
                  type="email"
                  id="mailId"
                  {...register('email')}
                  className={`block w-full rounded py-[6px] px-[10px] ${
                    errors?.email?.message ? 'border-red-500' : ''
                  }`}
                />
                {errors?.email?.message && (
                  <span className="inline-flex text-sm text-red-500" role="alert">
                    {errors?.email?.message}
                  </span>
                )}
              </section>
              <section className="my-2 flex flex-col ">
                <div>
                  <label htmlFor="nearestExchange">Nearest Exchange</label>
                  <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">*</span>
                </div>
                <div className="relative">
                  <select
                    id="nearestExchange"
                    {...register('nearestExchange')}
                    className={`block w-full rounded py-[6px] px-[10px] ${
                      errors?.nearestExchange?.message ? 'border-red-500' : ''
                    }`}
                  >
                    <option value=""></option>
                    {nearestExchanges.map((exchange) => (
                      <option key={exchange[0]} value={exchange[1]}>
                        {exchange[1]}
                      </option>
                    ))}
                  </select>
                  <FaChevronDown
                    aria-hidden="true"
                    className="pointer-events-none absolute top-1/2 right-3 -translate-y-1/2 text-xs text-black-500"
                  />
                  {errors?.nearestExchange?.message && (
                    <span className="inline-flex text-sm text-red-500" role="alert">
                      {errors?.nearestExchange?.message}
                    </span>
                  )}
                </div>
              </section>
              <section className=" my-[30px] flex flex-col gap-6 md:flex-row md:justify-around">
                <button
                  type="submit"
                  className=" h-[40px]  w-full rounded-sm  bg-blue-900 px-3 text-affes-xl font-bold leading-[19.2px] text-white md:w-2/5"
                >
                  {`Enter Sweepstakes`}
                </button>
                <button
                  className=" h-[40px] w-full rounded-sm  bg-blue-900 px-3 text-affes-xl font-bold leading-[19.2px] text-white md:w-1/4"
                  onClick={(event) => refreshPage(event)}
                >
                  Refresh
                </button>
              </section>
            </section>
          </form>
          {maxLimitModal && (
            <MaxLimit
              closeModal={setMaxLimitModal}
              customMessage={sweepFormError ? sweepFormError : submitSuccessMsg}
              isSweepstake={true}
            />
          )}
        </section>
      )}
    </>
  );
};

export default Sweepstake;
